import React from 'react';

const Book = () => {
    return (
        <div>
            <h1>This is book</h1>
        </div>
    );
};

export default Book;