import React from 'react';
import NavBar from '../NavBar/NavBar';
import director from '../../../Images/siteimage/director.jpg';
import Footer from '../Footer/Footer';
import { Container } from 'react-bootstrap';
import './Message.css';
import AnimatedText from 'react-animated-text-content';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { baseUrl, imagesUrl } from '../../../config';
import AOS from 'aos';

AOS.init();

const messages = [
    {
        'img': director
    }
]

const Message = () => {
    const [loading, setLoading] = useState([true])
    const [viewMessage, setMessage] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-message`).then(res => {
            if (res.data.status === 200) {
                setMessage(res.data.messages)
                setLoading(false);
            }
        });


    }, []);
    return (
        <div className='all-background'>
            <div>
                <NavBar />
            </div>
            <Container>
                <div className='welcom-sector text-center'>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Welcome to NEXUS Employment Ltd</AnimatedText></h4>

                    <div className='margue-section-2'>
                        {/* {loading ?
        <div className="text-center">
          <Spinner animation="border" variant="danger"/>
        </div> : */}
                        <div>
                            {
                                messages.map(messegeimg =>
                                    <div className='margue-section-3'>
                                        {/* <img width="200px" src={`${imagesUrl}/${messegeimg.image}`} alt="" /> */}
                                        <img width="200px" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000" src={messegeimg.img} alt="" />
                                    </div>
                                )
                            }
                        </div>


                        {/* } */}

                        <br />
                        <br />
                        <h3 style={{ color: "white" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Message from Managing Director</AnimatedText></h3>
                        <br />
                        <br />
                        <div style={{padding:"20px"}} className="row">
                            <div className="col-md-6">
                                <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Assalamu'alaikumWarahmatullahi Wabarakatuh
                                    It is with great pride and immense pleasure that
                                    I do hereby heartily welcome you all to NEXUS
                                    EMPLOYMENT LTD. and would to sincerely
                                    thank all current and prospective customers
                                    based throughout the country for your trust and
                                    cooperation during our journey.
                                    </p>

                                    <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    The NEXUS EMPLOYMENT LTD., is a trusted and
                        reliable overseas manpower recruiting agency
                        registered and authorized by the Government of
                        Bangladesh as a Foreign Employment
                        Consultant as well as a Bangladeshi Manpower
                        supply company with the motto “Our work is
                        the presentation of our capabilities” with our
                        head office in Dhaka Bangladesh.
                                    </p>
                                    <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">

                            NEXUS EMPLOYMENT LTD is managed by a
                            workforce consisting of energetic professionals
                            from professional entrepreneurs. We are always
                            honored by good relations with the Government
                            of Bangladesh, associations related to foreign
                            employment agencies, and social workers who
                            have networks all over Bangladesh.
                        </p>
                        <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        We are the company established with the
                            objectives to provide anticipated platforms
                            along with the training and placement to the
                            skilled, semiskilled, and unskilled individuals all
                            over the globe. We always aim to provide the
                            best job opportunities for
                        </p>

                            </div>
                            <div className="col-md-6">
                            <p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                             our clients to overseas companies. The
                            dedication and promptness of our professional
                            and specialist staff have led this company to
                            remain the first choice for both foreign
                            employment seekers and overseas employer
                            companies. Being government registered
                            manpower agency in Bangladesh, we always
                            follow the transparent, proven, and legal
                            procedure to recruit Bangladeshi manpower for
                            overseas companies. You can feel absolutely safe
                            getting recruited through our agency. We have
                            thousands of satisfied clients and candidates.
                        </p>
                        <p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            We always welcome your feedback /suggestions
                            that will assist us to make improvements to our
                            existing services. It will be our pleasure to serve
                            you and an honor to be associated with you. We,
                            at NEXUS EMPLOYMENT LTD, shall continue our
                            journey of excellence and success by putting all
                            the best services at the most affordable prices.
                        </p>
                        <p data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        I would like to thank all clients around the world
                            who have shown belief and trust in our
                            capabilities and supported us throughout this
                            journey.
                        </p>
                        <p data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            
                            Best Regards,
                            <br />
                            <strong>MD KAMRUZZAMAN</strong>
                            <br />
                            Managing Director
                            <br />
                            NEXUS EMPLOYMENT LTD.

                        </p>

                            </div>
                        </div>
                        
                    </div>

                </div>
                {/* <AnimatedText
  type="char" // animate words or chars
  animation={{x: '200px',y: '-20px',
    scale: 1.1,ease: 'ease-in-out', }}animationType="rifle"interval={0.02}duration={0.4}tag="p"className="animated-paragraph"includeWhiteSpacesthreshold={0.1} rootMargin="20%">
  Content to animate Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita debitis ipsum aspernatur cumque, commodi, est, exercitationem ratione modi tempore dolorem corporis sit distinctio temporibus quaerat repellendus ipsam culpa iusto reprehenderit.
</AnimatedText>; */}

            </Container>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
};

export default Message;