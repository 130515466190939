/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import './CategoryWorker.css'
import { Container } from 'react-bootstrap';
import AnimatedText from 'react-animated-text-content';
import underconstruction from '../../../Images/Message/underconstruction.gif'
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';



const CategoryWorker = () => {
    return (
        <section className='all-background'>
            <div>
                <NavBar />
            </div>
            <div className='welcom-sector text-center'>
            <div className='container'>

                <Container>
                    <h4 style={{backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px"}}><AnimatedText type="char" animationType= "float" interval={0.07}duration={1.5}>Category of Worker</AnimatedText></h4>
                    <div className='profile margue-section-2'>
                     <div className='under-const-img'>
                     <img src={underconstruction} alt="" />
                     <p>Under Construction</p>
                     </div>

                    </div>
                </Container>
            </div>
        </div>
        <div className='mt-5'>
                <Footer />
        </div>
        </section>
    );
};

export default CategoryWorker;