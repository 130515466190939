import React from 'react';
import { Container } from 'react-bootstrap';
import AntNav from './AntNav';

const AntShow = () => {
    return (
        <div>
            <div style={{backgroundColor: "lightblue"}}>
                <AntNav />

            </div>
           <Container>
           <div style={{marginTop: "200px"}}>
                <h2 className='text-center'>This is Ant Design</h2>
                <br />
                <br />
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis velit id obcaecati eius. Quod maiores nam ratione illo! Quibusdam laboriosam sunt qui quis tempora eos, labore maxime odio veniam rem quo ex ab libero eveniet dolor architecto? Cupiditate impedit maxime aperiam vitae necessitatibus deserunt harum? Incidunt mollitia ut omnis nihil molestias tenetur harum corporis laborum distinctio vitae. Explicabo, maxime modi. Minus quia delectus maiores officiis amet id temporibus alias eaque ab nam quas pariatur nisi consectetur repellendus, labore molestiae aperiam est, dignissimos praesentium et? Voluptatum perferendis quasi ipsam! Aperiam exercitationem iure dolore nihil? Natus quasi accusamus sed dolorum, odio deleniti.</p>
                <br />
                <br />
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi pariatur minima deleniti incidunt asperiores, dolorem aliquid quidem distinctio perferendis nisi rem, amet possimus veritatis totam illum. Deleniti tempora, praesentium, corrupti velit et aperiam neque, aliquid ullam dolores earum nobis dignissimos molestias ipsam. Temporibus labore cupiditate illo ullam ab laborum reiciendis dignissimos perspiciatis sunt quam distinctio ipsum quae similique, qui voluptas accusamus amet? Mollitia reprehenderit maiores animi molestiae fugiat officia nemo impedit odit magni aut, laboriosam ad accusantium, amet explicabo. Vero voluptatem obcaecati impedit doloremque fuga illum ducimus alias, distinctio molestias ratione dolor reiciendis, quisquam, pariatur quas cumque sequi. Numquam ducimus fugiat impedit excepturi possimus architecto temporibus! Accusamus, molestias voluptate! Sequi illum suscipit, numquam nemo omnis consequatur, sint architecto ex nihil, ea natus animi facilis culpa tenetur! Mollitia perferendis dolor dolores rem! Neque, eveniet dignissimos nesciunt temporibus culpa cum veritatis? Suscipit perferendis amet natus commodi totam ipsam! Perspiciatis ducimus esse non corporis temporibus architecto beatae ipsam voluptate, neque rerum. Dolorem iste, maxime nihil itaque, consequatur ipsa vitae modi sapiente, eaque odio minima enim voluptatem aliquid obcaecati deleniti qui aspernatur beatae tempora in fugit quasi corrupti nisi molestias illum! In, totam commodi dicta atque aliquam natus eaque ipsa deleniti velit tempora nostrum.</p>
            </div>
           </Container>
        </div>
    );
};

export default AntShow;