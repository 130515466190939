import React from 'react';
import AnimatedText from 'react-animated-text-content';
import { Card, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Document1 from '../../../../Images/Documents/Document1.jpg'
import {Spinner} from 'react-bootstrap';
import AOS from 'aos';
import NavBar from '../../NavBar/NavBar';
import Footer from '../../Footer/Footer';
import skilled from '../../../../Images/BannerNewAdd/skilled.png'

AOS.init();

const SkilledWorkers = () => {

    return (
        <div className='all-background'>
            <div>
                <NavBar />
            </div>
            <div className='welcom-sector text-center'>
                <div className='container'>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Skilled Worker</AnimatedText></h4>
                    <div className='margue-section-2'>
                   <div className='mt-5 mb-5'>
                
                    <div className="row">
                        <div className="col-md-12 text-center">
                        <img src={skilled} alt="" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "justify", padding: "10px 60px 20px 60px", fontSize: "15px"}}>
                            <p>Skilled worker is an evolution of a person’s proficiency, wages, and specialization in a field. Has special skill, training, knowledge, and (usually acquired) ability in their work.</p>
                        </div>
                    </div>

                   </div>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
};

export default SkilledWorkers;