import React from 'react';
import { Container } from 'react-bootstrap';
import './OurValues.css';
import AnimatedText from 'react-animated-text-content';
import network from '../../../Images/BannerNewAdd/people.png';
import worker from '../../../Images/BannerNewAdd/relation.png';
import team from '../../../Images/BannerNewAdd/team.png';
import AOS from 'aos';
import NavBar from '../../../Components/HomePageComponents/NavBar/NavBar';
import Footer from '../../../Components/HomePageComponents/Footer/Footer';

AOS.init();

const OurValues = () => {
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
                <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Our Values</AnimatedText></h4>
                <div className='margue-section-2'>

                   <div className='text-center'>
                    {/* <h1>Our Values</h1> */}
                    <br />
                    

                   </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={network} alt="" />

                        </div>
                        <div className="col-md-6">
                            <p><strong>PEOPLE: Our most important assets </strong></p>
                            <p>
                            We put our clients and candidates first before anything else. We deal with people in our line of work
and therefore, we are dedicated to searching for the best talents for our clients and to helping our
candidates reach their maximum potential. We care about people and the role of work in their lives. We
respect people as individuals, trusting and enabling them to achieve their aims in work and in life. We
help people develop their careers through planning, work, coaching and training. We recognize
everyone's contribution to our success - our staff, our clients and our candidates. We encourage and
reward achievement.
                            </p>


                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <p><strong>COMMITMENTS: Establishing Our beliefs through everything we do</strong></p>
                            <div>
                                <p>We pro-actively tap the market for excellent candidates. Once the candidates have been identified, we conduct in-depth interviews with each individual and obtain detailed information (on topics such as educational background, experience, job history, career goals, personality traits, compensation, etc.).</p>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={worker} alt="" />


                        </div>
                    </div>

                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={team} alt="" />


                        </div>
                        <div className="col-md-6">
                            <p><strong>RELATIONSHIPS & TRUST: Thinking Long Term </strong></p>
                            <div className='choose-core-value-align ml-4'>
                                <p style={{ color: "white" }}>In this industry, we always think for the long term. We
want to establish good and lasting relationships with our
clients and candidates, and we believe that trust and
determination are the ultimate recipe for success.</p>
                            </div>


                        </div>
                    </div>

                    {/* <h3 className='text-center' style={{marginTop: "50px", color: "white"}}>WE SERVE</h3>
                 <div>
                    <p>Along with the fast development of the global economy, human resources for the construction industry are always in high demand. NEXUS Employment Ltd. has been providing international employers with impressive services and outstanding by truly listening to their needs and requirements. We can supply Professional, Skilled, Semi-Skilled, and Un-Skilled Bangladeshi workers in various industries including construction, oil, and gas, manufacturing, agriculture, transportation, hospitality, and others.</p>
                 </div>

                 <div className='mt-1'>
                 <strong>Professional workers</strong> are an evolution of a person’s proficiency. Has the standards of education and training that prepare members of the profession with the particular knowledge and skills necessary to perform their specific role within that profession. 
                 </div> */}
                </div>


            </Container>

            <div style={{ marginTop: "100px" }}>
                <Footer />
            </div>
        </div>
    );
};

export default OurValues;