import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './DashboardNav.css'

const DashboardNav = ({ setShowSidebar, show }) => {
    const { panel } = useParams();
    return (
        <Navbar expand="lg" variant="light" bg="white">
        <Container fluid>
            <button  onClick={() => setShowSidebar(!show)} type="button" id="sidebarCollapse" className={show ? "navbar-btn active" : "navbar-btn"} >
                <span></span>
                <span></span>
                <span></span>
            </button>
            <Navbar.Brand>
                <h2
                    className="d-inline-block ml-md-3 mb-0"
                    style={{ fontSize: "1.4rem", fontWeight: "600" }}>
                    {
                     panel === "profile" ? "Profile"
                     :panel === "add-document-require" ? "Add SAUDI ARABIA"
                     :panel === "manage-document-require" ? "Manage SAUDI ARABIA"
                     :panel === "add-message" ? "Add Message"
                     :panel === "manage-message" ? "Manage Message"
                     :panel === "add-contact" ? "Add Contact"
                     :panel === "manage-contact" ? "Manage Contact"
                     :panel === "add-welcome" ? "Add Welcome"
                     :panel === "manage-welcome" ? "Manage Welcome"
                     :panel === "add-pdffile" ? "Add Brochure"
                     :panel === "manage-pdffile" ? "Manage Brochure"
                     :panel === "add-phpto-gallery" ? "Add photo Gallery"
                     :panel === "manage-phpto-gallery" ? "Manage Photo Gallery"
                    
                     : ""
                    }
                </h2>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="ml-auto" >
                    <p className='poper-align1'>NEXUS EMPLOYMENT LTD.</p>
                </div>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    );
};

export default DashboardNav;