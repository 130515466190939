import React from 'react';
import './MainFooter.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faHome, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faComments, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import touchandsolve from '../../../Images/touchandsolve.png'

const Footer = () => {

    return (
        <footer id="footers">
            

            <div className="container"  data-aos="zoom-in" data-aos-delay="40" data-aos-duration="1300">

                <div className="row">

                    <div className="footers-box col-md-4 col-sm-6">

                        {/* <h3 className="service-font1">ABOUT US</h3> */}
                        <div className='footers-img-align'>
                        <img src={touchandsolve} alt="" />
                        </div>
                        <br />
                       
                        <br />
                       {/* <div className='button-align-subscribe'>
                           <input type="text" />
                           <Button>Subscribe</Button>
                       </div> */}

                        <div className="ficons">
                            <a href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="/"><FontAwesomeIcon icon={faBehance} /></a>
                        </div>

                    </div>

                    <div className="footers-box col-md-8 col-sm-6">

                        <h3 className="service-font1">CONTACT US</h3>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faHome} />
                            <span> 51/1, VIP Tower (9th Floor), VIP Road, Nayapaltan,Dhaka-1000, Bangladesh.</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faPhone} />
                            <span> +880 1841987700 ,    +880 1886797700</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>nexsltd21@gmail.com</span>
                        </div>

                    </div>

                </div>

                <div className="copy-right-container">
                    <p>&copy; {new Date().getFullYear()}, NEXUS Employment Ltd. All Rights Reserved, Maintenance by <span></span><a rel="noreferrer" target="_blank" href="http://arman.verticelilas.com/">Arman</a></p>
                </div>

            </div>
        </footer>

    );
};

export default Footer;