import React from 'react';
import NavBar from '../NavBar/NavBar';
import director from '../../../Images/siteimage/director.jpg'
import Footer from '../Footer/Footer';
import { Container } from 'react-bootstrap';
import './Management.css';
import AnimatedText from 'react-animated-text-content';
import Arrow from '@elsdoerfer/react-arrow';
import chart from '../../../Images/Message/chart.PNG';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
import { baseUrl, imagesUrl } from '../../../config';
import AOS from 'aos';

AOS.init();

const management = [
    {
        'img': director
    }
]

const Message = () => {
    const [loading, setLoading] = useState([true])
    const [viewMessage, setMessage] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-message`).then(res=>{
            if(res.data.status === 200)
            {
                setMessage(res.data.messages)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='all-background'>
            <div>
                <NavBar />
            </div>
            <Container>
                <div className='welcom-sector text-center'>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>MANAGEMENT</AnimatedText></h4>

                    <div className='margue-section-2'>
                    {/* {loading ?
        <div className="text-center">
          <Spinner animation="border" variant="danger"/>
        </div> : */}
                    <div>
                        {
                            management.map(messageimg => 
                                <div className='margue-section-3'>
                        {/* <img width="200px" src={`${imagesUrl}/${messageimg.image}`} alt="" /> */}
                        <img data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000" width="200px" src={messageimg.img} alt="" />
                        </div>
                                )
                        }
                    </div>
{/* } */}
                    
                        <br />
                        <br />
                        <br />
                           


                        <div className="chart-img-align">
                            <img src={chart} alt="" />

                        </div>

                        {/* <div className='management-chart-design'>

                            <p>Our Organogram</p>
                            <Arrow
                                angle={180}
                                length={20}
                                style={{
                                    width: '30px'
                                }}
                            />
                            <p>Chairman</p>
                            <p>Managing Director</p>
                            <p>General Manager</p>
                            <p>Asst.Manager</p>
                            <p>Departments</p>
                            <p></p>


                        </div> */}

                    </div>

                </div>
                {/* <AnimatedText
  type="char" // animate words or chars
  animation={{x: '200px',y: '-20px',
    scale: 1.1,ease: 'ease-in-out', }}animationType="rifle"interval={0.02}duration={0.4}tag="p"className="animated-paragraph"includeWhiteSpacesthreshold={0.1} rootMargin="20%">
  Content to animate Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita debitis ipsum aspernatur cumque, commodi, est, exercitationem ratione modi tempore dolorem corporis sit distinctio temporibus quaerat repellendus ipsam culpa iusto reprehenderit.
</AnimatedText>; */}

            </Container>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
};

export default Message;