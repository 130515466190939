import React from 'react';
import { Container } from 'react-bootstrap';
import AboutUsSide from '../AboutUsNave/OurDocuments/AboutUsSide/AboutUsSide';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import Notice from '../WelcomeSection/Notice';
import './Profile.css';
import AnimatedText from 'react-animated-text-content';
import AOS from 'aos';

AOS.init();

const Profile = () => {
    return (
        <div className='all-background'>
              <div style={{ marginBottom: "50px" }}>
            <NavBar />
        </div>
             <Container>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.9}>PROFILE</AnimatedText></h4>
                   <div className='profile margue-section-2' style={{marginBottom: "20px"}}>
                   <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000"> Business</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Overseas Employment Promoter</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">License No</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">RL-2030</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Year Of Establishment</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">2021</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Address</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">51/1, VIP Tower (9th Floor), VIP Road, Nayapaltan,Dhaka-1000, Bangladesh.</p>
 

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Mobile</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">+880 1841987700, +880 1886797700</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Email</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">nexsltd21@gmail.com</p>

{/* <b>Website</b>

<p>www.winneroverseas.com</p> */}

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Bankers</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Islami Bank Bangladesh Limited</p>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">The City Bank Limited</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Membership</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Bangladesh Association of International Recruiting Agencies (BAIRA).</p>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Association of Travel Agents of Bangladesh (ATAB)</p>

<b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Help Line</b>

<p data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">+880 1841987700 </p>     
                       </div>  
        </Container>

        <Footer />
        </div>

    );
};

export default Profile;