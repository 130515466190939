import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Book from './../../Components/DashboardComponents/Book/Book';
import './Dashboard.css';
import DashboardNav from './../../Components/DashboardComponents/DashboardNav/DashboardNav';
import SideBar from './../../Components/DashboardComponents/Sidebar/SideBar';
import AddDocument from '../../Components/DashboardComponents/AboutUs/Document/AddDocument/AddDocument';
import ManageDocument from '../../Components/DashboardComponents/AboutUs/Document/ManageDocument/ManageDocument';
import AddDocumentRequire from '../../Components/DashboardComponents/VisaProcess/DocumentRequire/AddDocumentRequire/AddDocumentRequire';
import ManageDocumentRequire from '../../Components/DashboardComponents/VisaProcess/DocumentRequire/ManageDocumentRequire/ManageDocumentRequire';
import AddMessage from '../../Components/DashboardComponents/Company/CompanyMessege/AddMessage';
import ManageMessage from '../../Components/DashboardComponents/Company/CompanyMessege/ManageMessage';
import AddContact from '../../Components/DashboardComponents/OurContact/AddContact';
import ManageContact from '../../Components/DashboardComponents/OurContact/ManageContact';
import AddWelcome from '../../Components/DashboardComponents/WelcomeSection/AddWelcome';
import ManageWelcome from '../../Components/DashboardComponents/WelcomeSection/ManageWelcome';
import AddBrochure from '../../Components/DashboardComponents/Brochure/AddBrochure';
import ManageBroucher from '../../Components/DashboardComponents/Brochure/ManageBroucher';
import AllProfile from '../../Components/DashboardComponents/AllProfile/AllProfile'
import AddPhotoGallery from '../../Components/DashboardComponents/Gallery/PhotoGallery/AddPhotoGallery';
import ManagePhotoGallery from '../../Components/DashboardComponents/Gallery/PhotoGallery/ManagePhotoGallery';


const Dashboard = () => {
    const { panel } = useParams();
    const [showSidebar, setShowSidebar] = useState(false);
    return (
        <main className="dashboard-container">
     <SideBar show={showSidebar} />
            <div  id="content">
               <DashboardNav setShowSidebar={setShowSidebar} show={showSidebar}  />
                {
                    
                    panel === 'add-document' ? <AddDocument />
                    :panel === 'profile' ? <AllProfile />
                    :panel === 'manage-document' ? <ManageDocument />
                    :panel === 'add-document-require' ? <AddDocumentRequire />
                    :panel === 'manage-document-require' ? <ManageDocumentRequire />
                    :panel === 'add-message' ? <AddMessage />
                    :panel === 'manage-message' ? <ManageMessage />
                    :panel === 'add-contact' ? <AddContact />
                    :panel === 'manage-contact' ? <ManageContact />
                    :panel === 'add-welcome' ? <AddWelcome />
                    :panel === 'manage-welcome' ? <ManageWelcome />
                    :panel === 'add-pdffile' ? <AddBrochure />
                    :panel === 'manage-pdffile' ? <ManageBroucher />
                    :panel === 'add-phpto-gallery' ? <AddPhotoGallery />
                    :panel === 'manage-phpto-gallery' ? <ManagePhotoGallery />
                    :panel === 'book' ? <Book />
                    
                    : null
                }
            </div>
        </main>
    );
};

export default Dashboard;