import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../NavBar/NavBar';
import './ContactForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faPhone, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { faWeebly } from '@fortawesome/free-brands-svg-icons';
import Footer from '../Footer/Footer';
import GoogleMap from '../GoogleMap/GoogleMap';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
import { baseUrl } from '../../../config';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import AOS from 'aos';

AOS.init();


const contactData = [
    {
        'address': '51/1, VIP Tower (9th Floor), VIP Road, Nayapaltan,Dhaka-1000, Bangladesh.',
        'mobile': '+880 1841987700',
        'mail': 'nexsltd21@gmail.com',
        'web':'www.nexuseltd.com',
    },
]

const ContactForm = () => {
    const [loading, setLoading] = useState([true])
    const [viewContact, setContact] = useState([])
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = data => {
        const loading = toast.loading('Please wait...!');
        emailjs.send('service_hreowqs', 'template_1hcm98g', data, 'user_AgkG3WXfXfnkcy25zXfZM')
            .then((res) => {
                toast.dismiss(loading);
                if (res.text === "OK") {
                    reset();
                    return swal("Thank you!", "Your message was sent successfully.", "success");
                }
                swal("Sorry!", "Something went wrong. Please try again later", "error");
            }, (err) => {
                toast.dismiss(loading);
                swal("Sorry!", "Something went wrong. Please try again later", "error")
            });
    };


    useEffect(() => {

        axios.get(`${baseUrl}/view-contact`).then(res=>{
            if(res.data.status === 200)
            {
                setContact(res.data.contacts)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
                <div className="contact-align0 row">
                    <div className="contact-align1 col-md-5">
                    {/* {loading ?
        <div className="text-center" style={{marginTop: "200px"}}>
          <Spinner animation="border" variant="danger"/>
        </div> : */}

                       <div>
                         <h4 data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Let's get in touch</h4>
                        <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">We're open for any suggestion or just to have a chat</p>

                        {
                                contactData.slice(0,1).map(allcontact =>
                                    <div className='contact-align12'>
                            <div data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000" className='d-flex'>
                                <FontAwesomeIcon className='font-awesome1' icon={faSearchLocation} /><p className="ml-4">{allcontact.address}</p>


                            </div>
                            <div className='d-flex'>
                                <FontAwesomeIcon className='font-awesome1' icon={faPhone} /><p className="ml-4">{allcontact.mobile}</p>
                            </div>

                            <div className='d-flex mt-4'>
                                <FontAwesomeIcon className='font-awesome1' icon={faMailBulk} /><p className="ml-4">{allcontact.mail}</p>
                            </div>
                            <div className='d-flex mt-4'>
                                <FontAwesomeIcon className='font-awesome1' icon={faWeebly} /><p className="ml-4">{allcontact.web}</p>
                            </div>
                        </div>
                                    )
                            }
                       </div>

                        {/* } */}

                    </div>
                    <div className="contact-align2 col-md-7">
                        <div data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000" class="containerss">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label for="fname">Full Name</label>
                                <input type="text" id="fname" name="fullname" placeholder="Your name.."{...register("fullname", { required: true })} required />

                                <label for="lname">Email</label>
                                <input type="text" id="lname" name="email" placeholder="Your last name.." {...register("email", { required: true })} required/>


                                <label for="subject">Subject</label>
                                <textarea id="subject" name="subject" placeholder="Write something.."{...register("subject", { required: true })} required style={{ height: "50px" }}></textarea>

                               
                                        <label>Message</label>
                                        <textarea name="message" placeholder='Give me a mail'  {...register("message", { required: true })} required style={{ height: "100px" }} />
                                 

                                <input type="submit" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </Container>
            <div style={{marginTop: "50px", marginBottom:"-5px"}}>
                <GoogleMap />
            </div>

            <div><Footer /></div>
        </div>
    );
};

export default ContactForm;
