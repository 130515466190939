import React from 'react';
import NavBar from '../../NavBar/NavBar';
import './BrochureDownlod.css'
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import AnimatedText from 'react-animated-text-content';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { baseUrl, imagesUrl } from '../../../../config';
import pdf from '../../../../Images/pdf.png'
import company from '../../../../Images/file/company.pdf'

// const brouchers = [
//     {
//         'img': pdf,
//         'file': company
//     }
// ]

const BrochureDownload = () => {
    const [loading, setLoading] = useState([true])
    const [viewBrochure, setBrochure] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-pdffile`).then(res => {
            if (res.data.status === 200) 
            {
                setBrochure(res.data.pdffiles)
                setLoading(false);
            }
        });


    }, []);
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container >
                <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>BROCHURE DOWNLOAD</AnimatedText></h4>
                <div style={{ marginBottom: "70px" }}>
                <div className='profile margue-section-2'>
                    
                    
                    {/* {loading ?
            <div className="text-center">
              <Spinner animation="border" variant="danger"/>
            </div> : */}
                <div style={{justifyContent: "center"}} className='row'>
                    
                    {/* {
                    brouchers?.map(brou => */}
                        
                            

                        
                 
                            

                                <div className='col-md-4'>
                                <Card className='broucer-content-align' style={{ width: '18rem'}}>
                                    <Card.Img className='text-center' width='100%' variant="top" src={pdf} />
                                    <Card.Body>
                                    
                                        {/* <a href={`${imagesUrl}/${brou.image}`} download > <Button className='download' variant="success"> Download</Button></a> */}
                                        <a href={company} download > <Button className='download' variant="success"> Download</Button></a>
                                    </Card.Body>
                                </Card>
                                </div>


                       
                           
                       
                    {/* )
                } */}
              
                </div>
            {/* } */}
               
                 </div>
                 </div>
            </Container>

            <div>
                <Footer />
            </div>
        </div>
    );
};

export default BrochureDownload;