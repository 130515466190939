/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import swal from 'sweetalert';
import touchandsolve from '../../Images/touchandsolve.png'
import 'antd/dist/antd.css'; 

const menu = (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <Nav.Link smooth as={HashLink} to="/performence" className="mr-3"><strong>Performence</strong></Nav.Link>
                ),
            },
            {
                key: '2',
                label: (
                    <Nav.Link smooth as={HashLink} to="/document" className="mr-3"><strong>Documents</strong></Nav.Link>
                ),
            
                
            },
            {
                key: '3',
                label: (
                    <Nav.Link smooth as={HashLink} to="/chooseus" className="mr-3"><strong>Why Choose Us</strong></Nav.Link>
                ),
               
            },
            {
                key: '3',
                label: (
                    <Nav.Link smooth as={HashLink} to="/chooseworker" className="mr-3"><strong>Why Choose BD Workers</strong></Nav.Link>
                ),
            },
        ]}
    />
);

const AntNav = () => {
    const history = useHistory();
    const [isSticky, setSticky] = useState(false);
    const [isCollapsed, setCollapsed] = useState(null);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        })
    }, []);

    const logoutSubmit = (e) => {
        e.preventDefault();

    }

    return (
        <Navbar expand="lg" className={(isSticky || isCollapsed) ? "slide in py-2 show shadow-sm navbar navbar-expand-md bg-white navbar-light   fixed-top" : "slide out show navbar navbar-expand-nd navbar-light py-2 fixed-top "}>
            <Container >
                <Navbar.Brand smooth as={HashLink} to="/"  > <Image style={{ height: '70px', width: '78px' }} src={touchandsolve} /> <strong></strong></Navbar.Brand>
                <Navbar.Toggle onClick={() => setCollapsed(!isCollapsed ? 'show' : null)} aria-controls="basic-navbar-nav" style={{ background: '#10bad4' }} />
                <Navbar.Collapse id="navbar-nav" >
                    <Nav className="ml-auto text-center">





                        












                        <Nav.Link smooth as={HashLink} to="/" className="mr-3"><strong>Home</strong></Nav.Link>
                        
                        <Dropdown overlay={menu}>
                  <Nav.Link className="mr-3" onClick={(e) => e.preventDefault()}><Space><strong>About Us</strong> <DownOutlined /> </Space></Nav.Link>
                        </Dropdown>

                        <Nav.Link smooth as={HashLink} to="#about" className="mr-3"><strong>About Us</strong></Nav.Link>
                        <Nav.Link smooth as={HashLink} to="#service" className="mr-3"><strong>Services</strong></Nav.Link>
                        <Nav.Link smooth as={HashLink} to="#blog" className="mr-3"><strong>Blogs</strong></Nav.Link>
                        <Nav.Link smooth as={HashLink} to='#reviews' className="mr-3"><strong>Testimonials</strong></Nav.Link>
                        <Nav.Link smooth as={HashLink} to="#our-team" className="mr-3"><strong>Our Team</strong></Nav.Link>
                        <Nav.Link smooth as={HashLink} to="#contact" className="mr-3"><strong>Contact</strong></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AntNav;