import { Phone } from '@material-ui/icons';
import React from 'react';
import {
  HashRouter as Router,
  Route, Switch
} from "react-router-dom";
import AboutUs from './Components/HomePageComponents/AboutUs/AboutUs';
import ChooseUs from './Components/HomePageComponents/AboutUsNave/ChooseUs/ChooseUs';
import ChooseWorker from './Components/HomePageComponents/AboutUsNave/ChooseWorker/ChooseWorker';
import OurDocuments from './Components/HomePageComponents/AboutUsNave/OurDocuments/OurDocuments';
import OurPerformence from './Components/HomePageComponents/AboutUsNave/OurPerformence/OurPerformence';
import ContactForm from './Components/HomePageComponents/ContactForm/ContactForm';
import BrochureDownload from './Components/HomePageComponents/Download/BrochureDownload/BrochureDownload';
import PhotoGallary from './Components/HomePageComponents/GallarySection/PhotoGallary/PhotoGallary';
import Management from './Components/HomePageComponents/Management/Management';
import Message from './Components/HomePageComponents/Message/Message';
import MissionVission from './Components/HomePageComponents/MissionVission/MissionVission';
import Profile from './Components/HomePageComponents/Profile/Profile';
import DocumentRequre from './Components/HomePageComponents/VisaProsses/DocumentRequre/DocumentRequre';
import VisaProsses from './Components/HomePageComponents/VisaProsses/VisaProsses/VisaProsses';
import Dashboard from './Pages/Dashboard/Dashboard';
import Home from './Pages/Home/Home';
import MainPage from './Pages/MainPage/MainPage';
import VisitingCard from './Components/HomePageComponents/UnderConstruction/VisitingCard/VisitingCard'
import AntShow from './Pages/AntNav/AntShow';
import './App.css'
import CategoryWorker from './Components/HomePageComponents/CategoryWorker/CategoryWorker';
import ImageGellary from './Components/HomePageComponents/ImageGellary/ImageGellary';
import Register from './Components/HomePageComponents/Auth/Register/Register';
import Login from './Components/HomePageComponents/Auth/Login/Login';
import IsLogedin from './Function/IsLogedin';
import axios from 'axios';
import ProfessionalWorkers from './Components/HomePageComponents/OurServices/ProfessionalWorkers/ProfessionalWorkers';
import SkilledWorkers from './Components/HomePageComponents/OurServices/SkilledWorkers/SkilledWorkers';
import SemiSkilledWorkers from './Components/HomePageComponents/OurServices/SemiSkilledWorkers/SemiSkilledWorkers';
import UnskilledWorkers from './Components/HomePageComponents/OurServices/UnskilledWorkers/UnskilledWorkers';
import OurValues from './Shared/ExtraAdd/OurValues/OurValues';


axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})

class App extends React.Component {
  componentDidMount(){
    document.title = "NEXUS Employment Ltd"
  }
  render() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/home">
          <Home />
        </Route>

        <Route path="/performence">
          <OurPerformence />
        </Route>
        <Route path="/massege">
          <Message />
        </Route>
        <Route path="/ourvalues">
          <OurValues />
        </Route>
        <Route path="/management">
          <Management />
        </Route>
        <Route path="/document">
          <OurDocuments />
        </Route>
        <Route path="/chooseus">
          <ChooseUs />
        </Route>
        <Route path="/chooseworker">
          <ChooseWorker/>
        </Route>
        <Route path="/profile">
          <Profile/>
        </Route>
        <Route path="/visaprosses">
          <VisaProsses />
        </Route>
        <Route path="/documentrequre">
          <DocumentRequre />
        </Route>
        <Route path="/missionvission">
          <MissionVission />
        </Route>
        <Route path="/contactform">
        <ContactForm />
        </Route>
        <Route path="/photogallary">
        <PhotoGallary />
        </Route>
        <Route path="/download">
          <BrochureDownload />
        </Route>
        <Route path="/information">
          <AboutUs />
        </Route>
        <Route path="/visitingcard">
          <VisitingCard />
        </Route>



        <Route path="/professionalworker">
          <ProfessionalWorkers />
        </Route>

        <Route path="/skilledworker">
          <SkilledWorkers />
        </Route>

        <Route path="/semiskilledworker">
          <SemiSkilledWorkers />
        </Route>

        <Route path="/unskilledworker">
          <UnskilledWorkers />
        </Route>




        <Route path="/antshow">
          <AntShow />
        </Route>
        <Route path="/categoryworker">
          <CategoryWorker />
        </Route>
        <Route path="/photogallerys">
          <ImageGellary />
        </Route>
        <Route path="/register">
            <Register/>
          </Route>
          <Route path="/login">
            <Login/>
          </Route>
          <IsLogedin>
        <Route path="/dashboard/:panel">
          <Dashboard />
        </Route>
        </IsLogedin>
        
      </Switch>
    </Router>
  );
};
}

export default App;