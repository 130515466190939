/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React from 'react';

const GoogleMap = () => {
  return (
    <div>
      <iframe style={{width:"100%", height:"300px", border:"0", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1826.1642148983512!2d90.41234550804893!3d23.7356648461492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b85f99c1e78d%3A0xbafd946228583fb7!2sVIP%20Tower!5e0!3m2!1sen!2sbd!4v1655445901557!5m2!1sen!2sbd"></iframe>
    </div>
  );
};

export default GoogleMap;