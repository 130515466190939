import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import image1 from '../../../../Images/siteimage/9 Wating Area Female.JPG';
import image2 from '../../../../Images/siteimage/2 Manager Desk.jpg';
import image3 from '../../../../Images/siteimage/3 Assistant Manager Desk.jpg';
import image4 from '../../../../Images/siteimage/4 Accounts Department.jpg';
import image5 from '../../../../Images/siteimage/5 Embassy & Manpower Department.jpg';
import image6 from '../../../../Images/siteimage/6 Customer Service Desk REP.jpg';
import image7 from '../../../../Images/siteimage/7 Customar Service Department.jpg';
import image8 from '../../../../Images/siteimage/11 Office Entrance copy.jpg 2.jpg';
import image9 from '../../../../Images/siteimage/1 Managing Director.jpg';
import image10 from '../../../../Images/siteimage/10 Wating Area Male 2.jpg';
import './PhotoGallary.css'
import NavBar from '../../NavBar/NavBar';
import touchandsolve from '../../../../Images/touchandsolve.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faHome, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faComments, faEnvelope } from '@fortawesome/free-regular-svg-icons';

const img = [
    {
        category: 'office',
        img: image1
    },
    { category: 'office', img: image10 },
    { category: 'office', img: image2 },
    { category: 'office', img: image3 },
    { category: 'office', img: image4 },
    { category: 'office', img: image5 },
    { category: 'deligate', img: image6 },
    { category: 'deligate', img: image7 },
    { category: 'deligate', img: image8 },
    { category: 'deligate', img: image9 },
    // {category:'other', img:image},
    // {category:'other', img:image},
    // {category:'other', img:image},
    // {category:'other', img:image},
    // {category:'other', img:image}
];



const PhotoGallary = () => {


    const [category, setCategory] = useState('');
    const [image, setImage] = useState([]);

    const handleAll = (e) => {
        const value = e?.target?.value;
        setCategory(value);
        if (value == 1) {
            const t = img.filter(test => test.category == 'office');
            setImage(t)
        }
        else if (value == 2) {
            const t = img.filter(test => test.category == 'deligate');
            setImage(t)
        }
        else if (value == 3) {
            const t = img.filter(test => test.category == 'other');
            setImage(t)
        }
        else {
            setImage(img)
        }

    }
    console.log(image)

    useEffect(() => {
        if (!category) {
            setImage(img)
        }
    }, [])



    return (
        <div className='all-background'>
            <div>
                <NavBar></NavBar>
            </div>
            <Container className='photogallery-alignment'>
                <div className='align-gallery-category d-flex justify-content-between'>
                    <div>
                        <h3 >Photo Gallary</h3>
                    </div>
                    <div className='d-flex'>
                        <h4>Category:</h4>
                        <Form.Select style={{ width: "200px", marginLeft: "20px" }} onChange={handleAll} aria-label="Default select example ">
                            <option >All</option>
                            <option value="1">Office</option>
                            <option value="2">Deligate</option>
                            <option value="3">Other</option>
                        </Form.Select>
                    </div>
                </div>
                <Row>
                    {
                        image?.map(photo => <Col sm={12} md={6} lg={4}>
                            <img style={{margin:"10px"}} width='100%' src={photo?.img} alt="" />

                        </Col>
                        )

                    }
                </Row>
            </Container>

            {/* {
               !category && image.map(test => <div>
                    <img src={test?.img} alt="" />
                    
                </div>)
                
            } */}
            
            <footer style={{marginTop: '100px'}} id="footerss">

            <div className="container"  data-aos="zoom-in" data-aos-delay="40" data-aos-duration="1300">

                <div className="row">

                    <div className="footerss-box col-md-4 col-sm-6">

                        {/* <h3 className="service-font1">ABOUT US</h3> */}
                        <div className='footerss-img-align'>
                        <img src={touchandsolve} alt="" />
                        </div>
                        <br />
                       
                        <br />
                       {/* <div className='button-align-subscribe'>
                           <input type="text" />
                           <Button>Subscribe</Button>
                       </div> */}

                        <div className="ficons">
                            <a href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="/"><FontAwesomeIcon icon={faBehance} /></a>
                        </div>

                    </div>

                    <div className="footerss-box col-md-8 col-sm-6">

                        <h3 className="service-font1">CONTACT US</h3>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faHome} />
                            <span> 51/1, VIP Tower (9th Floor), VIP Road, Nayapaltan,Dhaka-1000, Bangladesh.</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>+880 1841987700,    +880 1886797700</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>nexsltd21@gmail.com</span>
                        </div>

                    </div>

                </div>

                <div className="copy-right-container">
                    <p>&copy; {new Date().getFullYear()}, NEXUS Employment Ltd. All Rights Reserved, Maintenance by <span></span><a rel="noreferrer" target="_blank" href="http://arman.verticelilas.com/">Arman</a></p>
                </div>

            </div>
        </footer>



        </div>
    );
};

export default PhotoGallary;