/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import aboutus from '../../../Images/aboutus.png';
import './AboutUs.css'
import AOS from 'aos';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';


AOS.init();

const AboutUs = () => {
    return (
       <div>
        <div style={{backgroundColor: "#a7d8de"}}>
            <NavBar />
        </div>
         <div className='about-us-alignment'>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='container about-us-alignment2'>
                <br />
                <br />
                <br />
                {/* <h4 className='about-us-tittle-align' data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in-sine" data-aos-duration="1000" style={{ textAlign: 'center', marginBottom: '15px', color: '#10bad4' }}>About Us</h4> */}
                {/* <h3 className='about-us-tittle2-align' data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in-sine" data-aos-duration="1000">We provide best service for our valuable clients</h3> */}
                {/* <marquee behavior="left" direction="">this is arman</marquee> */}
                <br />
                <br />
                <br />
                <div className='row' style={{textAlign: "justify", padding: "10px 60px 20px 60px", fontSize: "15px"}}>
                    <div className='col-md-6'>
                        <h2 data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in-sine" data-aos-duration="1000" style={{textAlign: "center", color: "white", fontWeight: "700"}}>About Us</h2>
                        <p data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in-sine" data-aos-duration="1000" style={{textAlign: "center", marginBottom: "130px"}}>We provide best service for our valuable clients</p>
                        <p className='about-us-text-align-p'>We are one of the best Recruitment Agencies recognized by the Government of the People's Republic of Bangladesh under the registration number RL-2030. Nexus Employment Ltd. is the specialized manpower supply agency of Bangladesh, whose services are trusted and admired throughout the country and beyond borders as well.
                        </p>
                        <p>
    
                            We have highly trained and experienced professionals who are devoted to the work. Nexus Employment Ltd. has redefined the global 
                            </p>



                      

                    </div>
                    <div className='col-md-6'>
                        {/* <img data-aos="fade-up-right" data-aos-duration="1500" src={aboutus} alt="" /> */}
                        <p>
                            recruitment process to a predictably accurate process with defined parameters for matching the job skills of potential candidates with the job profiles of international companies. We can overseas placement agency provide international jobs for skilled, semi-skilled, and unskilled workers with various corporate giants in the SAUDI ARABIA, QATAR, OMAN, U.A.E, BAHRAIN, KUWAIT, MALAYSIA, AFRICA, ASIA PACIFIC COUNTRIES, EUROPIAN COUNTRIES.
                            </p>
                        <p>

                            Nexus Employment Ltd. follows a well-defined process and method. As an international recruitment agency, we cater to the needs of diverse industries like Construction, Hospitality, Engineering, Medical services, Oil & Gas shipping, and so on. International companies usually issue a demand letter detailing job spaces, and a letter of authority empowering sources to act as their recruitment consultants in Nepal and to deal with visa and other procedures. The overseas company also forwards a draft of the employment agreement between the employer and employee.
                        </p>

                    </div>
                </div>
                <div className='aboutus-img-align'>
                    <img src={aboutus} alt="" />
                </div>
            </div>
        </div>
        <div>
            <Footer />
        </div>
       </div>
    );
};

export default AboutUs;