import { Container, Nav, Navbar } from 'react-bootstrap';
import './MainNave.css';
import logo from '../../../Images/nexus.png';
import React from 'react'
import MovingText from 'react-moving-text'
import { HashLink } from 'react-router-hash-link';
import { Button, Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaHeart, FaGem, FaClipboardList } from 'react-icons/fa';
import { RiWhatsappFill } from 'react-icons/ri';
import TopBar from '../../../Components/HomePageComponents/TopBar/TopBar';



const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <Nav.Link smooth as={HashLink} to="/login" className="mr-3"><strong>Admin Panel</strong></Nav.Link>
        ),
      },
    ]}
  />
);



const MainNav = () => {


  return (
    <section>
      <div>
        <TopBar />
      </div>
      <Navbar className='main-nav-align' collapseOnSelect expand="lg" bg="blue" variant="dark">
      <Container>
        <Navbar.Brand className='border-align-right' href="#home"><img style={{ width: "70px" }} src={logo} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <MovingText
              presences="letters"
              type="effect3D"
              duration="8000ms"
              delay="8000ms"
              direction="normal"
              timing="ease"
              iteration="infinite"
              fillMode="none"> */}
              <Nav.Link className='text-gradient' style={{ fontSize: "28px" }} href="#">NEXUS Employment Ltd</Nav.Link>
            {/* </MovingText> */}

          </Nav>
          <Nav>
            <Nav.Link smooth as={HashLink} to="/massege" className="mr-3"><button className='btn btn-danger'>Menu</button></Nav.Link>
          </Nav>
          
          {/* <Nav>
            <Nav.Link smooth as={HashLink} to="/dashboard/profile" className="mr-3"><strong>Dashboard</strong></Nav.Link>
          </Nav> */}

          <Dropdown overlay={menu} placement="bottom" arrow>
      <button className='btn btn-danger'>Login</button>
    </Dropdown>

    {/* <Nav style={{marginLeft: "15px", marginTop: "-5px", fontSize:'35px'}}>
            <Nav.Link smooth as={HashLink} to="/massege" className="mr-3"><strong style={{color: "#25D366"}}>{<RiWhatsappFill />}</strong></Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
  </Navbar>
    </section>
  );
};

export default MainNav;