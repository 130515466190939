import React from 'react';
import { Container } from 'react-bootstrap';
import AboutUsSide from '../AboutUsNave/OurDocuments/AboutUsSide/AboutUsSide';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import Notice from '../WelcomeSection/Notice';
import AnimatedText from 'react-animated-text-content';
import vision from '../../../Images/nexus others/vision.gif';
import Mission from '../../../Images/nexus others/Mission.gif';
import './MissionVission.css';
import AOS from 'aos';

AOS.init();

const MissionVission = () => {
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
                        <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.9}>Mission and Vision</AnimatedText></h4>
                        <div>
                            <div className='profile margue-section-2'>
                                
                                <div className="row mission1" style={{marginTop: "70px"}}>
                                    <div className="col-md-5">
                                        <img className='mission-align-img' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000" src={Mission} alt="" />

                                    </div>
                                    <div className="col-md-7 mission2">
                                    <b> OUR MISSION </b>
                                        <p data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000"> <AnimatedText type="char" animationType= "float" interval={0.04}duration={0.4}> Achieving our vision by apprenticing with our clients to recruit, Manage and retain human resources by using our outstanding comprehension. </AnimatedText></p>

                                    </div>
                                </div>

                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="row mission1" style={{marginTop: "70px"}}>
                                    <div className="col-md-7 mission2">
                                    <b> OUR Vision </b>
                                        <p data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000"><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.2}>A dedicated services gateway to have the best personnel to provide this quality of services. Here is where we as Excellent Manpower Services employment agency can offer our services providing the right personnel to fulfill your expectations and reach the level of service you looking for.</AnimatedText></p>
                                        

                                    </div>
                                    <div className="col-md-5">
                                    
                                        <img data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000" style={{width: "100px", marginRight:"50px"}} src={vision} alt="" />

                                    </div>
                                </div>
                                <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        
                            </div>
                           
                </div>
            </Container>

            <div style={{marginTop: "100px"}}>
            <Footer />
            </div>
        </div>
    );
};

export default MissionVission;