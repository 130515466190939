import React from 'react';
import AnimatedText from 'react-animated-text-content';
import AOS from 'aos';
import unski from '../../../../Images/BannerNewAdd/unski.png'
import NavBar from '../../NavBar/NavBar';
import Footer from '../../Footer/Footer';


AOS.init();

const UnskilledWorkers = () => {

    return (
        <div className='all-background'>
            <div>
                <NavBar />
            </div>
            <div className='welcom-sector text-center'>
                <div className='container'>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Unskilled worker</AnimatedText></h4>
                    <div className='margue-section-2'>
                   <div className='mt-5 mb-5'>
                
                    <div className="row">
                        <div className="col-md-12 text-center">
                        <img style={{width:"500px"}} src={unski} alt="" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "justify", padding: "10px 60px 20px 60px", fontSize: "15px"}}>
                            <p>Unskilled worker is a segment of the workforce associated with a limited skill set or minimal economic value for the work performed. Generally characterized by lower educational attainment. Labor, Helper, or Housekeeper.</p>
                        </div>
                    </div>

                   </div>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
};

export default UnskilledWorkers;