import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import NavBar from '../../NavBar/NavBar';
import './ChooseUs.css';
import AnimatedText from 'react-animated-text-content';
import network from '../../../../Images/BannerNewAdd/network.png';
import worker from '../../../../Images/BannerNewAdd/worker.png';
import team from '../../../../Images/BannerNewAdd/team.png';
import AOS from 'aos';

AOS.init();

const ChooseUs = () => {
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
                <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>WHY CHOOSE US</AnimatedText></h4>
                <div className='margue-section-2'>

                    <div className="row">
                        <div className="col-md-6">
                            <p>Our genuine passion and enthusiasm for what we do, coupled with our agility enables us to respond swiftly to clients’ ensuring that our clients continue to use NEXUS Employment Ltd. time and time as their trusted recruitment partner. To ensure we uphold and promote the highest possible standards across our recruitment practices. Besides our passion for recruitment, we are pioneers in our sector and nationality recognized for our corporate social responsibility strategy. What makes us the most reliable source is our team of experts who collaborate to form an excellent management team. A fully computerized Office System and Virtual Databank designed to enable prompt communication among every staff for efficient and best service delivery. Our own reliable expertise for medical check-ups and aptitude tests enables us to handpick the best before we actually select anyone for final recruitment.
                            </p>
                            <br />
                            <strong>Overseas Recruitment Process</strong>
                            <p>
                                NEXUS Employment Ltd. follows a well-defined process and method. As an international recruitment agency, we cater to the needs of diverse industries like Construction, Hospitality, Engineering, Medical Services, Oil & Gas Shipping, and so on. International companies usually issue a demand letter detailing job spaces, and a letter of authority empowering sources to act as their recruitment consultants in Bangladesh and to deal with visas and other procedures. The overseas company also forwards a draft of the employment agreement between the employer and

                            </p>



                        </div>
                        <div className="col-md-6">
                            <p>
                                employee. Nexus Employment Ltd. then initiates the process of calling for applications from candidates, short listing, and then calling selected candidates for tests and oral interviews either by the employer or his authorized representative at suitable locations in Bangladesh.
                            </p>

                            <p>
                                Nexus Employment Ltd. the HR recruitment agency provides logistic support. Sometimes employers abroad delegate responsibilities to Nexus Employment Ltd. As an overseas placement agency, we follow a defined process to ensure the selection of only the perfect candidate matching the job profile. Industry experts in each field are called upon for interviews and tests jointly carried out with executives in HR specialization at Nexus Employment Ltd.  As a manpower recruitment firm, Nexus Employment Ltd. also arranges medical checkups of selected candidates at authorized centers. This is followed by initiating the visa process with the Embassy of the country of the employer, followed by ticketing and immigration processes. Nexus Employment Ltd. also gives a basic orientation program for chosen candidates prior to leaving Bangladesh, informing them about labour laws of the country of employment, the work environment in the company, and other information. We provide easy access to the best talents with a widespread network of offices located in all the major cities of the country. While our Head office is located in Dhaka, Bangladesh, we have our associate at BAIRA, Government of Bangladesh, Bureau Of Manpower, Employer & Training (BMET) and Ministry of Expatriates' Welfare and Overseas Employment. Our widespread network enables us to reach out to the widest audience, meeting all the recruitment needs of any organization.
                            </p>

                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={network} alt="" />

                        </div>
                        <div className="col-md-6">
                            <p><strong>Office Network</strong></p>
                            <p>
                                We provide easy access to the best talents with a widespread network of offices located in all the major cities of the country. While our Head office is located in Dhaka, Bangladesh, we have our associate at BAIRA, Government of Bangladesh, Bureau Of Manpower, Employer & Training (BMET) and Ministry of Expatriates' Welfare and Overseas Employment. Our widespread network enables us to reach out to the widest audience, meeting all the recruitment needs of any organization.
                            </p>


                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <p><strong>Our Work Process</strong></p>
                            <div>
                                <p>We pro-actively tap the market for excellent candidates. Once the candidates have been identified, we conduct in-depth interviews with each individual and obtain detailed information (on topics such as educational background, experience, job history, career goals, personality traits, compensation, etc.).</p>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={worker} alt="" />


                        </div>
                    </div>

                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <img style={{ width: "100%" }} src={team} alt="" />


                        </div>
                        <div className="col-md-6">
                            <p><strong>Our Team & Infrastructure</strong></p>
                            <div className='choose-core-value-align ml-4'>
                                <p style={{ color: "white" }}>We are equipped with powerful integrated recruitment technologies and have a. team of seasoned expert recruiters. We have all the latest facilities in the areas of communication, frequent transport, etc., leading to the expedition of the entire process. Additionally, all our recruiters have significant management experience. For most of the posts, they have “been there and seen it all”. They understand the job requirement completely and appreciated that it is the human resources that are the backbone of an organization’s success.</p>
                            </div>


                        </div>
                    </div>

                    {/* <h3 className='text-center' style={{marginTop: "50px", color: "white"}}>WE SERVE</h3>
                 <div>
                    <p>Along with the fast development of the global economy, human resources for the construction industry are always in high demand. NEXUS Employment Ltd. has been providing international employers with impressive services and outstanding by truly listening to their needs and requirements. We can supply Professional, Skilled, Semi-Skilled, and Un-Skilled Bangladeshi workers in various industries including construction, oil, and gas, manufacturing, agriculture, transportation, hospitality, and others.</p>
                 </div>

                 <div className='mt-1'>
                 <strong>Professional workers</strong> are an evolution of a person’s proficiency. Has the standards of education and training that prepare members of the profession with the particular knowledge and skills necessary to perform their specific role within that profession. 
                 </div> */}
                </div>


            </Container>

            <div style={{ marginTop: "100px" }}>
                <Footer />
            </div>
        </div>
    );
};

export default ChooseUs;