import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box } from '@material-ui/core';
import { Container } from 'react-bootstrap';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import photo1 from '../../../Images/siteimage/1 Managing Director.jpg';
import photo4 from '../../../Images/siteimage/4 Accounts Department.jpg';
import photo7 from '../../../Images/siteimage/3 Assistant Manager Desk.jpg';
import './ImageGellary.css'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
import { baseUrl, imagesUrl } from '../../../config';



const itemData = [
    {
      img: photo1,
      title: 'Bed',
    },
    {
      img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
      title: 'Books',
    },
    {
      img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
      title: 'Sink',
    },
    {
      img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
      title: 'Kitchen',
    },
    {
      img: photo4,
      title: 'Blinds',
    },
    {
      img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
      title: 'Chairs',
    },
    {
      img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
      title: 'Laptop',
    },
    {
      img: photo7,
      title: 'Doors',
    },
    {
      img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
      title: 'Storage',
    },
    {
      img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
      title: 'Candle',
    },
    {
      img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
      title: 'Coffee table',
    },
  ];

const ImageGellary = () => {

  const [loading, setLoading] = useState([true])
    const [viewImageGallery, setImageGallery] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-photogallery`).then(res=>{
            if(res.data.status === 200)
            {
                setImageGallery(res.data.photogallerys)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='all-background'>
            <div style={{marginBottom: "50px"}}>
                <NavBar />
            </div>
            <Container className='image-gallery-align'>
            <Box sx={{ width: 500, height: 450, overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={4} gap={8}>
        {viewImageGallery.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${imagesUrl}/${item.image}?w=248&fit=crop&auto=format`}
              srcSet={`${imagesUrl}/${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
            </Container>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default ImageGellary;