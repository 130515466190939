import React from 'react';
import Header from './../../Components/HomePageComponents/Header/Header';
import Footer from '../../Components/HomePageComponents/Footer/Footer';
import './Home.css'
import MarqueeText from '../../Components/HomePageComponents/MarqueeText/MarqueeText';
import WelcomeSection from '../../Components/HomePageComponents/WelcomeSection/WelcomeSection';

const home = () => {
    return (
        <div className='scrool-align'> 
            <Header />
            <MarqueeText />
            <WelcomeSection />
            <div style={{marginTop:"50px"}}>
            <Footer />
            </div>
        </div>
    );
};

export default home;