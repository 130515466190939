import React, { useEffect, useState } from 'react';
import { Button, Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import touchandsolve from '../../../Images/touchandsolve.png';
import './NavBar.css';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import 'antd/dist/antd.css'; 

const menu = (
  <Menu
      items={[
          // {
          //     key: '1',
          //     label: (
          //         <Nav.Link smooth as={HashLink} to="/performence" className="mr-3"><strong>Performence</strong></Nav.Link>
          //     ),
          // },
          {
            key: '1',
            label: (
                <Nav.Link smooth as={HashLink} to="/profile" className="mr-3"><strong>Profile</strong></Nav.Link>
            ),
        },
          {
              key: '2',
              label: (
                  <Nav.Link smooth as={HashLink} to="/document" className="mr-3"><strong>Office Documents</strong></Nav.Link>
              ),
          
              
          },
        {
          key: '3',
          label: (
              <Nav.Link smooth as={HashLink} to="/management" className="mr-3"><strong>Organizational Chart</strong></Nav.Link>
          ),
      },
          {
            key: '4',
            label: (
              <Nav.Link smooth as={HashLink} to="/missionvission" className="mr-3"><strong>Mission & Vision</strong></Nav.Link>
            ),
        },
        {
          key: '5',
          label: (
            <Nav.Link smooth as={HashLink} to="/ourvalues" className="mr-3"><strong> Our Values</strong></Nav.Link>
          ),
      },
          {
              key: '6',
              label: (
                  <Nav.Link smooth as={HashLink} to="/chooseus" className="mr-3"><strong>Why Choose Us</strong></Nav.Link>
              ),
             
          },
          // {
          //     key: '4',
          //     label: (
          //         <Nav.Link smooth as={HashLink} to="/chooseworker" className="mr-3"><strong>Why Choose BD Workers</strong></Nav.Link>
          //     ),
          // }

      ]}
  />
);
const menu2 = (
  <Menu
      items={[
        {
            key: '1',
            label: (
                <Nav.Link smooth as={HashLink} to="/visaprosses" className="mr-3"><strong> Recruitment Process</strong></Nav.Link>
            ),
        },
        {
          key: '2',
          label: (
              <Nav.Link smooth as={HashLink} to="/documentrequre" className="mr-3"><strong> Required Documents</strong></Nav.Link>
          ),
      },
    //   {
    //     key: '3',
    //     label: (
    //         <Nav.Link smooth as={HashLink} to="/categoryworker" className="mr-3"><strong>Category of Workers</strong></Nav.Link>
    //     ),
    // }

      ]}
  />
);

const menu3 = (
  <Menu
      items={[
        {
          key: '1',
          label: (
              <Nav.Link smooth as={HashLink} to="/massege" className="mr-3"><strong>Message</strong></Nav.Link>
          ),
      },
      
    {
      key: '2',
      label: (
          <Nav.Link smooth as={HashLink} to="/information" className="mr-3"><strong>Information</strong></Nav.Link>
      ),
  },
  

      ]}
  />
);


const menu6 = (
  <Menu
      items={[
        {
          key: '1',
          label: (
              <Nav.Link smooth as={HashLink} to="/professionalworker" className="mr-3"><strong>Professional Worker</strong></Nav.Link>
          ),
      },
      {
        key: '2',
        label: (
            <Nav.Link smooth as={HashLink} to="/skilledworker" className="mr-3"><strong>Skilled Worker</strong></Nav.Link>
        ),
    },
    {
      key: '3',
      label: (
          <Nav.Link smooth as={HashLink} to="/semiskilledworker" className="mr-3"><strong> Semi-Skilled Worker</strong></Nav.Link>
      ),
  },
  {
    key: '4',
    label: (
        <Nav.Link smooth as={HashLink} to="/unskilledworker" className="mr-3"><strong> Unskilled Worker</strong></Nav.Link>
    ),
},
  

      ]}
  />
);


const menu4 = (
  <Menu
      items={[
        {
            key: '1',
            label: (
                <Nav.Link smooth as={HashLink} to="/download" className="mr-3"><strong>Brochure Download</strong></Nav.Link>
            ),
        },
        {
          key: '2',
          label: (
              <Nav.Link smooth as={HashLink} to="/visitingcard" className="mr-3"><strong>Visiting Card</strong></Nav.Link>
          ),
      }

      ]}
  />
);
const menu5 = (
  <Menu
      items={[
        {
            key: '1',
            label: (
                <Nav.Link smooth as={HashLink} to="/photogallary" className="mr-3"><strong>Photo Gallery</strong></Nav.Link>
            ),
        },
      //   {
      //     key: '2',
      //     label: (
      //         <Nav.Link smooth as={HashLink} to="#video" className="mr-3"><strong>Video Gallery</strong></Nav.Link>
      //     ),
      // }

      ]}
  />
);

const NavBar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isCollapsed, setCollapsed] = useState(null);
  

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    })
  }, []);

  return (
    <Navbar expand="lg" className={(isSticky || isCollapsed) ? "slide in py-2 show shadow-sm navbar navbar-expand-md bg-white navbar-light   fixed-top" : "slide out show navbar navbar-expand-nd navbar-light py-2 "}>
      <Container >
        <Navbar.Brand smooth as={HashLink} to="/"  > <Image style={{ width: '63px' }} src={touchandsolve} /> <strong className='nav-site-name-align'>NEXUS</strong></Navbar.Brand>
        <Navbar.Toggle onClick={() => setCollapsed(!isCollapsed ? 'show' : null)} aria-controls="basic-navbar-nav" style={{ background: '#10bad4' }} />
        <Navbar.Collapse id="navbar-nav" >
          <Nav className="ml-auto text-center">
            <Nav.Link smooth as={HashLink} to="/" className="mr-3"><strong>Home</strong></Nav.Link>

            <Dropdown overlay={menu} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>About Us</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>

                        <Dropdown overlay={menu3} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>Company</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>

                        <Dropdown overlay={menu6} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>Services</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>


                        <Dropdown overlay={menu4} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>Download</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>



                        <Dropdown overlay={menu2} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>Visa Process</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>

                       


                        <Dropdown overlay={menu5} placement="bottom">
                  <Nav.Link onClick={(e) => e.preventDefault()}><Space><strong>Gallery</strong> <DownOutlined style={{fontSize: "10px", marginLeft: "-8px"}} /> </Space></Nav.Link>
                        </Dropdown>


                        <Nav.Link smooth as={HashLink} to="/contactform" className="mr-3"><strong>Contact Us</strong></Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;