import React from 'react';
import { Container } from 'react-bootstrap';
import AboutUsSide from '../../AboutUsNave/OurDocuments/AboutUsSide/AboutUsSide';
import Footer from '../../Footer/Footer';
import NavBar from '../../NavBar/NavBar';
import Notice from '../../WelcomeSection/Notice';
import AnimatedText from 'react-animated-text-content';
import AOS from 'aos';

AOS.init();

const VisaProsses = () => {
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
                        <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign: "center" }}><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.9}> Recruitment Process</AnimatedText></h4>
                        <div>
                            <div className='profile margue-section-2'>

                                <div className="row">
                                    <div className="col-md-6">
                                        <b>REGULATORY DOCUMENTS</b>
                                        <p>Winner's precise & technical recruitment procedure is divided into Eleven (11) parts advertisement in leading media, pre-screening, interview & trade test,medical checkup followed by documentation, visa Process, Orientation &Mobilization. After receiving the original visa documents we go to concern office (BMET) to complete the Govt. formalities. Upon Receipt the Following Formal Documents</p>

                                    </div>
                                    <div className="col-md-6">
                                    <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000"> After the medical checkup is done, we process for the visa for the candidates who are fit and ready to travel </b>
                                    <ol data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    <li>
                                        Soon the visa stamped, we keep informing the candidate's VISA status so that they get ready for departure.
                                    </li>
                                    <li>
                                        we will complete travel formalities upon receipt of/endorsement of Visa and inform clients regarding the departure of the candidate
                                    </li>
                                </ol>
                                    </div>
                                </div>
                              


                               <div className='row'>
                                <div className="col-md-6">
                                <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Pre -Departure Orientation </b>
                                <ol data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    <li>
                                        It is obligatory on us by the government to arrange pre-orientation to the candidates before their departure.
                                    </li>
                                    <li>
                                        Hence, we give them through an orientation about their employer's salary, working conditions, culture, and manners, etc,
                                    </li>

                                    <li>
                                        We believe the orientation would help the candidates to inure a new climate to their working conditions initially towards higher productivity.
                                    </li>

                                    <li>
                                        The purpose of the orientation is to give the workers some suggestion of their Place of Work and environment before their departure so that they can adjust easily to their new environment on their arrival in the place of work.
                                    </li>

                                    <li>
                                        We have designed the courses according to the country to orient for the workers getting deployed for foreign employment,
                                    </li>

                                    <li>
                                        It is aimed to prove the knowledge of the immigration, the rules, and regulation of the concerned country, labor laws, along with ritual, social taboos, tradition and culture, and the basic knowledge of the language
                                    </li>
                                    <li>
                                        Departure arrangement.
                                    </li>
                                    <li>
                                        The travel arrangement is the final procedure.
                                    </li>
                                    <li>
                                        Before their departure, we make them the affidavit to be signed between the worker and the recruitment agency.
                                    </li>
                                    <li>
                                        After signing the contract with the workers, we make travel arrangements. This also includes handing over all the documents (original passport. air Ticket. and orientation certificate along with an orientation book) to the employee. They are now ready to deploy.
                                    </li>
                                </ol>

                                </div>
                                <div className="col-md-6">
                                <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    Documentation Head: The individual files will be maintained for all selected candidates, which will contain the following documents.
                                </b>
                                <br />
                                <br />

                                <ol data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    <li>
                                        Application Received AR form
                                    </li>
                                    <li>Passport -Original: For name, DOB, DOE, DOL, and Place of Issue </li>
                                    <li>Personal Details: Mother, father, Spouse, and children for overseas </li>
                                    <li>Complete Resume of candidates </li>
                                    <li>Work Experience Educational Certificates </li>
                                    <li>Academic candidates </li>
                                    <li>Technical Qualification candidates</li>
                                    <li>Driving License if any </li>
                                    <li>Ten PP size recent Photographs </li>
                                    <li>Medical Report </li>
                                    <li>Employment agreement</li>
                                </ol>

                                </div>

                               </div>

                               <div className="row">
                                <div className="col-md-6">
                                <div className='mt-3'>
                                    <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Immigration</b>
                                    <p data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Clearance: We also help the recruited candidates to obtain the Immigration clearance from the Department of Labor (BMET) and we assist the candidates to be familiar with the necessary formalities to be performed while going abroad like Embarkation form Departure Form security clearance etc.</p>

                                </div>
                                <div className='mt-3'>
                                    <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">The departure of the workers</b>
                                    <ol data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                        <li>After completion of all the processes and formalities, we inform the employer company about the flight details and time of arrival in the latter's country, by fax, email, or telephone SMS </li>
                                        <li>
                                            Then our office representative drops the employees' Airport in Dhaka, Chittagong.
                                        </li>
                                    </ol>
                                </div>

                                </div>
                                <div className="col-md-6">
                                <div className='mt-3'>
                                    <b data-aos="fade-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">Mobilization</b>
                                    <ol data-aos="fade-left" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                        <li>On receipt of the visa, we do the processing of the workers. We try to dispatch them as soon as possible.</li>
                                        <li>As per our experience, we dispatch them in around 10-15 days after receipt of the visa.</li>
                                        <li>We brief the workers regarding the procedure to enter the airport, cross the immigration, and the way during the flight to the destination.</li>
                                        <li>Besides, the procedure to clear the immigration in the diction, rules, regulations, traditions, cultural attitude towards the citizen and the other migrate workers of the traditions, culture, attitude towards the citizen and the other migrate workers of the concerned respective country.</li>
                                    </ol>
                                    <b>With best wishes, workers leave to the airport for their destination.</b>
                                </div>

                                </div>
                               </div>

                                

                                

                                
                                

                                

                            </div>
                        </div>
            </Container>
            <div style={{marginTop: "100px"}}>
            <Footer />
            </div>
            
        </div>
    );
};

export default VisaProsses;