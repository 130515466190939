/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Image, Row, Carousel, Spinner, Nav} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import security from '../../../Images/BannerNewAdd/security.png';
import server from '../../../Images/BannerNewAdd/server.png';
import pworker from '../../../Images/BannerNewAdd/pworker.png';
import skiled from '../../../Images/BannerNewAdd/skiled.png';
import unski from '../../../Images/BannerNewAdd/unski.png';
import handshake from '../../../Images/BannerNewAdd/handshake.png';
import semi from '../../../Images/BannerNewAdd/semi.png';
import './BannerNewAdd.css'
import axios from 'axios';
import {baseUrl, imagesUrl} from '../../../config';
import {useHistory} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';


const headerBanner = [


  {
    "id": 1,
    "tittle": "A Very Nice and Warm Welcome To Dearest All",
    "description": "",
    "img": handshake
  },
  {
    "id": 2,
    "tittle": "Professional Worker",
    "description": "Professional workers means personnel certified and approved by the Department of Education and shall include, but not be limited to, speech and ...",
    "img": pworker
  },
  {
    "id": 3,
    "tittle": " Skilled Worker",
    "description": "The skilled worker must meet the educational, training, or experience requirements of the job opportunity. Relevant post-secondary education may ...",
    "img": skiled
  },
  {
    "id": 4,
    "tittle": " Semi Skilled Worker",
    "description": "The skilled worker must meet the educational, training, or experience requirements of the job opportunity. Relevant post-secondary education may ...",
    "img": semi
  },
  {
    "id": 5,
    "tittle": "  Unskilled Worker",
    "description": "An unskilled worker is an employee who does not use reasoning or intellectual abilities in their line of work. These workers are typically found ...",
    "img": unski
  }
]


const BannerNewAdd = () => {
  const history = useHistory;
  const [loading, setLoading] = useState([false])
  const [viewBanner, setBanner] = useState([])


  useEffect(() => {

    axios.get(`${baseUrl}/view-header`).then(res => {
      if (res.data.status === 200) {
        setBanner(res.data.headers)
        setLoading(false);
      }
    });
  }, []);

  return (
    <div id='bannernew'>
     
        <Carousel fade controls={false}>

          {
            headerBanner.map(banner =>

              <Carousel.Item interval={2000}>

                <Container fluid>

                  <Row className="align-items-center justify-content-center banner">
                    <Col md={6} className="p-md-5 order-2 order-md-1">
                      <Fade left duration={2000} distance="40px">
                        <h1 style={{fontWeight: "700", color: "white"}}>{banner.tittle}</h1>
                        <p className="banner-text-align my-4 pr-md-5">{banner.description}</p>
                        {/* <Link to="#service"><Button className='btn btn-main'>Learn More</Button></Link> */}
                        <Nav.Link smooth as={HashLink} to="/massege" className="mr-3"><Button>Learn More</Button></Nav.Link>

                      </Fade>
                    </Col>
                    <Col md={6} className="order-1 order-md-2">
                      <Fade right duration={2000} distance="40px">
                        <Image className='banner-img' src={banner.img} fluid/>
                      </Fade>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
            )
          }


        </Carousel>
    
    </div>
  )
};

export default BannerNewAdd;
