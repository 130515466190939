import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import NavBar from '../../NavBar/NavBar';
import AnimatedText from 'react-animated-text-content';

const ChooseWorker = () => {
    return (
        <div className='all-background'>
        <div style={{ marginBottom: "50px" }}>
            <NavBar />
        </div>
        <Container>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign:"center" }}><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.9}>WHY CHOOSE BD WORKER</AnimatedText></h4>
                    <div className='margue-section-2'>
<div className='text-center'>
<h5>Bangladesh: Seeking Better Employment </h5>

<h5>Conditions for Better Socioeconomic Outcomes</h5>
</div>
                        <p> The beneficial advantages that attract the overseas employers to recruit Bangladeshi workers are:</p>
                        <ol>
                        <li>Bangladesh is a Manpower pool with an ever increasing production of highly qualified skilled and un-skilled workers.</li>
                        <li>The Workers are equipped with the real and tested Knowledge of modern technology.</li>
                        <li>Bangladesh workers are peace loving and religious minded with amiable disposition.</li>
                        <li>Workers of Bangladesh are extremely loyal to their masters and devoted to their duties.</li>
                        <li>Discipline, Patience, Perseverance, Efficiency and honesty are the salient features in the character of Bangladeshi Works.</li>
                        <li>Bangladeshi workers are Dutiful, Faithful, Methodical and Calculative.</li>
                        <li>Bangladeshis are hard-working by nature because survival is the first question of them</li>
                        <li>Bangladesh workers bear a good moral character. Workforce's from Bangladeshi are normally satisfied with the agreed wages.</li>
                        <li>Manpower from Bangladesh stands for guarantee of quality work of any sort.</li>
                        <li>Bangladeshi Manpower fields the highest outcome with the cheapest.</li>
                        <li>Bangladeshi workers usually are not addicted to any sort of narcotic drugs of drinks, which makes a man unable to discharge his duties smoothly.</li>
                        <li>Bangladeshi is endowed with highly productive labor force.</li>
                        <li>The wages of a labor is fundamentally on his skill and the standers of living of the country which he belongs to.</li>
                        <li>As the per capita GDP and the standard of living in Bangladeshi relatively lower compared to others lab our intensive countries such Srilanka, Pakistan, India, Nepal, Thailand is the lower ones.</li>
                        <li>The prescribed wages of Bangladeshi workers are absolutely lower compared to others countries, whether it is for anun-skilled worker of a fully skilled one.</li>
                        <li>The prescribed wages of Bangladeshi workers are absolutely lower compared to others countries, whether it is for an un-skilled worker of a fully skilled one.</li>
                        <li>The Bangladesh Government Considering the present depressed economic condition prevailing in this region has significantly reduced the minimum standard Wages of the Bangladeshi workers for placement abroad.</li>
                        <li>Even as the overseas employment position in other countries such as Philippines Pakistan, India, Nepal, Indonesia, Thailand Srilanka has stared declining, Bangladesh is achieving a faster and steady growth in this sector and the same has also resulted in the manifold increases in the remittances of the wages by the remittances of the wages earners from abroad Bangladesh is situated in close proximity among Mid-Eastern and south Eastern Asian Countries, it takes only 5 hours flying time from Dhaka to cities in The Mid –Eastern countries and 2.5 hours flying time in, South East Asian Countries.</li>
                        <li>Being tropical countries, our workers are used to working in a hot climate, which confirms to climatic conditions of almost all the Middle- East and South Asian countries.</li>
                        <li>Being Muslim countries, each and every worker is fully aware of the custom, Tradition and religious sanctions of the host, countries, which eventually becomes easier on the part of the Bangladeshi work force to follow, adapt Accept and honors.</li>
                        </ol>
                    </div>

        </Container>

        <div>
            <Footer />
        </div>
    </div>
    );
};

export default ChooseWorker;