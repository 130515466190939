import React from 'react';
import AnimatedText from 'react-animated-text-content';
import Footer from '../../Footer/Footer';
import NavBar from '../../NavBar/NavBar';
import { Card, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl, imagesUrl } from '../../../../config';
import Document1 from '../../../../Images/Documents/Document1.jpg'
import Document2 from '../../../../Images/Documents/Document2.jpg'
import Document3 from '../../../../Images/Documents/Document3.jpg'
import {Spinner} from 'react-bootstrap';
import AOS from 'aos';

AOS.init();

const allDocument = [
    {   
        'tittle': 'BAIRA MEMBERSHIP CERTIFICATE',
        'documents1': Document1
    },
    {   
        'tittle': 'Recruiting Licence NEXUS',
        'documents1': Document2
    },
    {   
        'tittle': 'TRADE LICENCE',
        'documents1': Document3
    },
]

const OurDocuments = () => {
    const [loading, setLoading] = useState([true])
    const [viewDocument, setDocument] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-document`).then(res=>{
            if(res.data.status === 200)
            {
                setDocument(res.data.documents)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='all-background'>
            <div>
                <NavBar />
            </div>
            <div className='welcom-sector text-center'>
                <div className='container'>
                    <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>OUR DOCUMENT</AnimatedText></h4>
                    <div className='margue-section-2'>
                   <div className='mt-5 mb-5'>
                
                   {/* {loading ?
        <div className="text-center">
          <Spinner animation="border" variant="danger"/>
        </div> : */}

<Row>
{
    allDocument.map(imag => 
        <Col style={{margin: "10px"}}>
        <Card style={{ width: '18rem' }}>
            {/* <Card.Img variant="top" src={`${imagesUrl}/${imag.image}`} /> */}
            <Card.Img data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000" variant="top" src={imag.documents1} />
            <Card.Body style={{textAlign:"center"}}>
                <Card.Title data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000" style={{color: "black"}}>{imag.tittle.slice(0, 17)}</Card.Title>
                {/* <Button  variant="primary">Download</Button> */}
            </Card.Body>
        </Card>
        </Col>

    )
}

{/* } */}
</Row>
                   </div>


                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
};

export default OurDocuments;