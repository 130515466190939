/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import coverphoto from '../../Images/siteimage/coverphoto.jpg';
import './Banner.css';





const Banner = () => {
   

    return (
        <div id='banner'>
            <div className="col-md-12 banner-img-align">
            <img src={coverphoto} alt="" />
            </div>
   
    </div>
)};

export default Banner;