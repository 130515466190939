import React, { useEffect } from 'react';
import './WelcomeSection.css';
import AnimatedText from 'react-animated-text-content';
import { useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../config';
import { Spinner } from 'react-bootstrap';

const messages = [
    {
        "message": "NEXUS Employment Ltd is one of the best Manpower Recruiting agencies in Bangladesh, known for its professional and excellent services. It is our motto to supply the right people, with the right skills, at the right time and place. Winner has been providing recruitment, training, and consultancy services to clients throughout the world since 1999.Our staffs are skilled and trained in their perspective fields. We always try to stay with our valued clients through the latest technologies and giving up to date information. Above all, we give the biggest priority to our valued customers.",


    }
]

const WelcomeSection = () => {
    const [loading, setLoading] = useState([true])
    const [viewWelcome, setWelcome] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-welcome`).then(res=>{
            if(res.data.status === 200)
            {
                setWelcome(res.data.welcomes)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='welcom-sector text-center'>
            <div className='container'>
                <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px" }}><AnimatedText type="char" animationType="float" interval={0.07} duration={0.9}>Welcome to NEXUS Employment Ltd.</AnimatedText></h4>
                <div className='margue-section-2'>
                    
                {/* {loading ?
        <div className="text-center">
          <Spinner animation="border" variant="danger"/>
        </div> : */}

                    <div>
                    {
                        messages.slice(0,1).map((welcome) => 
                           { return <div><p style={{fontSize: "15px"}}><AnimatedText type="char" animationType="float" interval={0.04} duration={0.4}>{welcome.message}</AnimatedText></p></div>}
                            )
                            
                    }
                    </div>

                {/* } */}
                </div>
            </div>
        </div>
    );
};

export default WelcomeSection;