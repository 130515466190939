import React, { useState } from 'react';
import NavBar from '../../NavBar/NavBar';
import swal from 'sweetalert';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../config';



const Login = () => {
    const history = useHistory([]);

    const [loginInput, setLogin] = useState({
        name: '',
        password: '',
        error:[],
    })


    const handleInput = (e) =>{
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value })
    }





    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }
        axios.post(`${baseUrl}/login`, data).then(res=>{
        console.log("Datas",res.data);

       if(res.data.status === 200)
       {
        localStorage.setItem('auth_token', res.data.token);
        localStorage.setItem('auth_name', res.data.username);
        swal("Success",res.data.message, "success");
        history.push('/dashboard/profile');
       }
       else if(res.data.status === 401)
       {
        swal("Warning",res.data.message, "warning");
       }
       else
       {
            setLogin({...loginInput, error: res.data.validation_errors});
       }
    });
    }

    

    return (
        <div>
            <div style={{marginBottom: "100px"}}>
            <NavBar />
            </div>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Login</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={loginSubmit}>

                                    <div className="form-group mb-3">
                                        <label>E-mail ID</label>
                                        <input type="text" name='email' className='form-control' onChange={handleInput} value={loginInput.email} />
                                        <span>{loginInput.error.email}</span>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Password</label>
                                        <input type="password" name='password' className='form-control' onChange={handleInput} value={loginInput.password} />
                                        <span>{loginInput.error.password}</span>
                                    </div>

                                    <div className="form-group mb-3">
                                        <button type='submit' className='btn btn-primary'>Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;