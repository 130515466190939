import React from 'react';
import './TopBar.css';
import { faMailBulk, faPhone, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faMailchimp, faWeebly, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const TopBar = () => {
    return (
        <div className='tobbar-align'>
            <div className="col-md-12">
                 <div className="ficonss">
                            <a href="/"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                            <a href="https://wa.me/qr/TWE7OZWZLBQMP1"><FontAwesomeIcon icon={faWhatsappSquare} /></a>
                            <a href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="/"><FontAwesomeIcon icon={faBehance} /></a>
                        </div>
            </div>
           
        </div>
    );
};

export default TopBar;