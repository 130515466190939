import React from 'react';

const IsLogedin = (props) => {
    if(localStorage.getItem('auth_token') !== null){
        return (
            <>{props.children}</>
        );
    }
    return null;
};

export default IsLogedin;