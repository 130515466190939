import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import touchandsolve from '../../../Images/touchandsolve.png'
import './Footer.css'
import GoogleMap from '../GoogleMap/GoogleMap';

const Footer = () => {

    return (
        <footer id="footer">

            <div className="container"  data-aos="zoom-in" data-aos-delay="40" data-aos-duration="1300">

                <div className="row">

                    <div className="footer-box col-md-3 col-sm-6">

                        {/* <h3 className="service-font1">ABOUT US</h3> */}
                        <div className='footer-img-align'>
                        <img src={touchandsolve} alt="" />
                        </div>
                        <br />
                       
                        <br />
                       {/* <div className='button-align-subscribe'>
                           <input type="text" />
                           <Button>Subscribe</Button>
                       </div> */}

                        <div className="ficons">
                            <a href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="/"><FontAwesomeIcon icon={faBehance} /></a>
                        </div>

                    </div>

                    <div className="footer-box col-md-3 col-sm-4">

                        <h3 className="service-font1">CONTACT US</h3>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faHome} />
                            <span> 51/1, VIP Tower (9th Floor), VIP Road, Nayapaltan,Dhaka-1000, Bangladesh.</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>+880 1841987700 <br /> +880 1886797700</span>
                        </div>

                        <div className="contact-icon d-flex align-items-center mb-4">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>nexsltd21@gmail.com</span>
                        </div>

                    </div>

                    <div className="footer-box col-md-3 col-sm-6">

                        <h3 className="service-font1">SITE LINKS</h3>

                        <ul> 
                            <li><Link to="/" ><FontAwesomeIcon icon={faChevronRight} />Home</Link></li>
                            <li><Link to="/" ><FontAwesomeIcon icon={faChevronRight} />Message</Link></li>
                            <li><Link to="/" ><FontAwesomeIcon icon={faChevronRight} />About Us</Link></li>
                            <li><Link to="/" ><FontAwesomeIcon icon={faChevronRight} />Management</Link></li>
                            <li><Link to="/" ><FontAwesomeIcon icon={faChevronRight} />Profile</Link></li>
                        </ul>

                    </div>

                    <div className="footer-box col-md-3 col-sm-6">
                        <h3 className="service-font1">USEFUL LINKS</h3>
                        <ul> 
                            <li><p><a rel="noreferrer" target="_blank" href="http://www.probashi.gov.bd/"><FontAwesomeIcon icon={faChevronRight} />Ministry</a></p></li>
                            <li><p><a rel="noreferrer" target="_blank" href="http://www.wewb.gov.bd/"><FontAwesomeIcon icon={faChevronRight} />WEWB</a></p></li>
                            <li><p><a rel="noreferrer" target="_blank" href="http://www.pkb.gov.bd/"><FontAwesomeIcon icon={faChevronRight} />Probashi Kallyan Bank</a></p></li>
                            <li><p><a rel="noreferrer" target="_blank" href="http://www.boesl.org.bd/"><FontAwesomeIcon icon={faChevronRight} />BOESL</a></p></li>
                            <li><p><a rel="noreferrer" target="_blank" href="http://baira.org.bd/"><FontAwesomeIcon icon={faChevronRight} />BAIRA</a></p></li>
                        </ul>


                    </div>

                </div>

                <div className="copy-right-container">
                    <p>&copy; {new Date().getFullYear()}, NEXUS Employment Ltd. All Rights Reserved, Maintenance by <span></span><a rel="noreferrer" target="_blank" href="http://arman.verticelilas.com/">Arman</a></p>
                </div>

            </div>
        </footer>

    );
};

export default Footer;