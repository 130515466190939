import axios from 'axios';
import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { baseUrl } from '../../../config';
import avater from '../../../Images/Avatar face.png';


const AllProfile = () => {
    const history = useHistory();



    const authName = (localStorage.getItem('auth_name'))

    const logoutSubmit = (e) => {
        e.preventDefault();

        axios.interceptors.request.use(function (config) {
            const token = localStorage.getItem('auth_token');
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
        })
        axios.post(`${baseUrl}/logout`).then(res => {
            console.log("Datas", res.data);

            if (res.data.status === 200) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                swal("Success", res.data.message, "success");
                history.push('/login');
            }
        });
    }

    console.log("test profile")
    return (
        <Container style={{ maxWidth: "30rem" }}>
            <Card className="border-0 shadow">
                <Card.Header as={"h4"} className="text-center border-0 mt-1">My Profile</Card.Header>
                <Card.Body className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                        <img src={avater} alt="..." className="rounded-circle" width="150" style={{ boxShadow: '0 8px 20px -4px #95abbb' }} />
                        <div className="mt-3">
                            <h4>{authName}</h4>
                        </div>
                        <Button variant='info' onClick={logoutSubmit} className='main-button'>Log Out</Button>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AllProfile;