import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { baseUrl, imagesUrl } from '../../../../../config';
import TableSpinner from '../../../TableSpinner/TableSpinner';

const ManageDocument = () => {
    const [loading, setLoading] = useState([true])
    const [viewServices, setServices] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-document`).then(res=>{
            if(res.data.status === 200)
            {
                setServices(res.data.documents)
                setLoading(false);
                // console.log("Datas", res.data.servicess);
            }
        });
        

    }, []);


    const deleteServices = (e, id) => {
            e.preventDefault();

            const thisClicked = e.currentTarget;
            thisClicked.innerText = "Deleteing";

            axios.delete(`${baseUrl}/delete-document/${id}`).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();
            }
            else if(res.data.status === 404){
                swal("Success", res.data.message, "success");
                thisClicked.innerText ="Delete";
            }
        });
    }
    
        if(loading)
        {
            return <TableSpinner />
        }
        else
        
            {
                var display_services = "";
               
                display_services = viewServices.map( (item, index) => {
                    return (
                        <tr key={index}>
                            {/* <td>{item.id}</td> */}
                            <td>{item.tittle}</td>
                            <td><img src={`${imagesUrl}/${item.image}`} width='50px' alt="Images"/></td>
                            {/* <td>
                                <Link to={`edit-services/${item.id}`} className="btn btn-success btn-sm">Edit</Link>
                            </td> */}
                            <td><button type='button' onClick={(e) => deleteServices(e, item.id)} className='btn btn-danger btn-sm'>Delete</button></td>
                        </tr>
                    );
                });
            

        }
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>
                                    <Link to={'/dashboard/add-document'} className="btn btn-primary btn-sm float-end"> Add Document</Link>
                                </h4>
                            </div>
                            <div className="card-body">
                                <div className='table-responsive'>
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            {/* <th>ID</th> */}
                                            <th>Tittle</th>
                                            <th>Images</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {display_services}
                                    </tbody>
                                </table>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageDocument;