/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import './MarqueeText.css';

const MarqueeText = () => {
    return (
        <div class="breaking-news-section">
				<span id="btext">Head Line</span>
				<marquee direction="left" onmouseover="this.stop()" onmouseout="this.start()">
					<a href="/" class="breaking-news">
						{/* <p class="bntime">11 Jan 2019</p> */}
						Welcome to NEXUS Employment Ltd</a>
					{/* <a href="/" class="breaking-news"><p class="bntime">Our Site</p>www.nexuseltd.com</a> */}
					{/* <a href="/" class="breaking-news"><p class="bntime">Contact</p>+880 1886797700</a> */}
				</marquee>
			</div>
    );
};

export default MarqueeText;