import React from 'react';
import AnimatedText from 'react-animated-text-content';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import NavBar from '../../NavBar/NavBar';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
import { baseUrl } from '../../../../config';
import AOS from 'aos';

AOS.init();


const DocumentRequre = () => {
    const [loading, setLoading] = useState([true])
    const [viewDocumentRequire, setViewDocumentRequire] = useState([])


    useEffect(() => {

        axios.get(`${baseUrl}/view-documentrequire`).then(res=>{
            if(res.data.status === 200)
            {
                setViewDocumentRequire(res.data.documentrequires)
                setLoading(false);
            }
        });
        

    }, []);
    return (
        <div className='all-background'>
            <div style={{ marginBottom: "50px" }}>
                <NavBar />
            </div>
            <Container>
       
                        <h4 style={{ backgroundColor: "black", borderRadius: "20px 20px 0px 0px", color: "white", padding: "15px", textAlign:"center" }}><AnimatedText type="char" animationType= "float" interval={0.07}duration={0.9}>DOCUMENTS REQUIRES</AnimatedText></h4>
                        <div>
                            <div className='profile margue-section-2'>
                                <div className="row">
                                    <div className="col-md-6">
                                    <b> REGULATORY DOCUMENTS </b>
                                <p>
                                    Winner's precise & technical recruitment procedure is divided into Eleven (11) parts advertisement in leading media, pre-screening, interview & trade test,medical checkup followed by documentation, visa Process, Orientation &Mobilization. After receiving the original visa documents we go to concern office (BMET) to complete the Govt. formalities. Upon Receipt the Following Formal Documents

                                </p>
                                 <p className='mt-4 text-center'>(To be signed and stamped on your company letterhead)</p>

                                 <br />
                                 <div className='mt-4'> 
                                    <b>Libya, Qatar, UAE, Kuwait, Bahrain, Oman & Malaysia</b>
                                </div>

                                <ol>
                                    <li>Demand Letter (Should be attested by theChamber of Commerce and Ministry of Foreign Affairs).</li>
                                    <li>Special Power of Attorney (Should beattested by the Chamber of Commerce and Ministry of Foreign Affairs).</li>
                                    <li>Recruitment Agreement/ Agency Agreement(Between Employer and Recruiting Agency/ Our Agency).</li>
                                    <li>Sample Employment Contract (BetweenEmployer and Employee).</li>
                                    <li>Guarantee Letter.</li>
                                    <li>Verification on Documents and Approval.</li>
                                </ol>

                                <br />
                                <b className='mt-4'>INSTRUCTION FOR EMPLOYER</b>

                                <p className='mt-2'>Demand Letter Attestation by the Bangladesh Embassy Any employer willing to recruit manpower from Bangladesh will have to submit their demand letter of recruitment for attestation in Bangladesh Embassy with following documents.</p>

                                    </div>







                                    <div className="col-md-6">

                                    <div>
                                    <b>SAUDI ARABIA </b>
                                    {/* {
                                       viewDocumentRequire.map((saudiaaribia, index) => 
                                        {
                                            return (
                                                <ul key={index}>
                                            <li>{saudiaaribia.tittle}</li>
                                        </ul>
                                            )
                                        }
                                       ) 
                                    } */}
                                    <ol>
                                        <li>Visa Slip</li>
                                        <li>Consulate Authorization letter (Wakala).</li>
                                        <li>Electronic Wakala.</li>
                                        <li>Demand Letter (Should be attested by the Chamber of Commerce and Ministry of Foreign Affairs).</li>
                                        <li>Special Power of Attorney (Should be attested by the Chamber of Commerce and Ministry of Foreign Affairs).</li>
                                        <li>Commercial Registration (Legally translated into English language, If Necessary).</li>
                                        <li>Sample Employment Contract (Between Employer and Employee).</li>
                                    </ol>
                                </div>
                                <br />
                                <p>On receiving of the original document we move toward the Bangladesh labor ministry for the pre-approval from the government. During this BMET may directly contact you for the authentication of the documents. On such cases we request you for your kind co-operation with this verification process.</p>

                                <br />
                                <ol>
                                        <li>Demand Letter</li>
                                        <li>Power of Attorney in favor of Recruiting Agency in Bangladesh</li>
                                    </ol>

                                    </div>
                                </div>
                                
                               


                                

                                

                                <div className='mt-3'>
                                    
                                </div>

                                

                                <div className='mt-5'>
                                    

                                </div>

                               

                           
                           

                        
                            </div>

                </div>
            </Container>

            <div style={{marginTop: "100px"}}>
            <Footer />
            </div>
        </div>
    );
};

export default DocumentRequre;