import React from 'react';
import GoogleMap from '../../Components/HomePageComponents/GoogleMap/GoogleMap';
import Footer from './MainFooter/MainFooter';
import MainNav from './MainNav/MainNav';
import './MainPage.css';
import BannerNewAdd from '../../Shared/ExtraAdd/BannerNewAdd/BannerNewAdd'
import ParticlesBackgrounds from '../../Shared/ExtraAdd/Particles/ParticleBackground'
import InquaryPage from './MainNav/InquaryPage';

const MainPage = () => {
    return (
        <section className='mainpage-css-align'>
            <div>
            <MainNav />
            </div>
            <div className='particle1'>
        <ParticlesBackgrounds/>
      </div>
      <div>

            <div className='container'>
                <BannerNewAdd />
            </div>
      </div>
      <div>
        <InquaryPage />
      </div>
            {/* <div class="bg-home" data-jarallax='{"speed": 0.3}' id="home">
                <div class="bg-overlay"></div>
                <div class="home-center">
                    <div class="home-desc-center">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-8 col-md-6">
                                    <div class="title-heading">
                                        <i class="mdi mdi-bookmark-check text-custom h2"></i>
                                     
                                        <div className='row card-align-res'>
                                            
                                            
                                        </div>
                                       
                                        <div class="card-align-mainpage mt-4 pt-2">


                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                                    <div class="bg-white index-form rounded p-4 mt-5">
                                        <h4 class="text-capitalize text-center font-weight-normal">Drop Your Inquary</h4>

                                        <form class="login-form mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <label>Name <span class="text-danger">*</span></label>
                                                        <input name="name" id="name3" type="text" class="form-control" placeholder="Name" required="" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <label>Email <span class="text-danger">*</span></label>
                                                        <input type="email" id="email3" class="form-control" placeholder="Email" name="email" required="" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <label>Mobile <span class="text-danger">*</span></label>
                                                        <input type="number" id="email3" class="form-control" placeholder="Number" name="email" required="" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <label>Description <span class="text-danger">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Description" required="" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 mb-0">
                                                    <button class="btn btn-custom w-100">Send</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div class="row">
                                <div class="home-shape">
                                    <img src="images/shp05.png" alt="" class="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div style={{marginBottom: "-5px", marginTop:"5px"}}>
                <GoogleMap />
            </div>
            <div>
                <Footer />
            </div>
        </section>
    );
};

export default MainPage;