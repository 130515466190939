import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { baseUrl } from '../../../../config';
import NavBar from '../../NavBar/NavBar';



const Register = () => {
    const history = useHistory();

    const[registerInput, setRegister] = useState({
        name:'',
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value});
    }


    const registerSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: registerInput.name,
            email: registerInput.email,
            password: registerInput.password,
        }
        const headers = {
           
                Accept: 'application/json',
                ContentType: 'application/json',
        }

        // console.log("datas", data);

        axios.post(`${baseUrl}/register`, data, headers).then(res => {
            if(res.data.status === 200)
            {
                localStorage.setItem('auth_token', res.data.token);
                localStorage.setItem('auth_name', res.data.username);
                swal("Success",res.data.message, "success");
                history.push('/dashboard/profile');
            }
            else
            {
                setRegister({...registerInput, error_list: res.data.validation_errors});

            }


        });
 



    }



    
    return (
        <div>
            <div style={{marginBottom: "100px"}}>
            <NavBar />
            </div>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Register</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={registerSubmit}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Full Name</label>
                                        <input type="text" name='name' onChange={handleInput} value={registerInput.name} className='form-control'  />
                                        <small className="text-danger">{registerInput.error_list.name}</small>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">E-mail ID</label>
                                        <input type="email" name='email' onChange={handleInput} value={registerInput.email} className='form-control'  />
                                        <small className="text-danger">{registerInput.error_list.email}</small>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Password</label>
                                        <input type="password" name='password' onChange={handleInput} value={registerInput.password} className='form-control'  />
                                        <small className="text-danger">{registerInput.error_list.password}</small>
                                    </div>
                                    {/* <div className="form-group mb-3">
                                        <label htmlFor="">Confirm Password</label>
                                        <input type="text" name='confirm_password' onChange={handleInput} value={registerInput.name} className='form-control'  />
                                    </div> */}
                                    <div className="form-group mb-3">
                                        <button type="submit" className='btn btn-primary'>Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;